<template>
  <v-row justify="center">
    <v-col cols="5" class="mb-5">
      <!-- Header -->
      <h2 class="pt-5 text-center">Users</h2>
      <!-- Loading -->
      <v-row v-if="loading" class="pt-5" justify="center">
        <v-icon>mdi mdi-loading mdi-spin</v-icon>
      </v-row>
      <!-- Error -->
      <v-row v-if="error" class="pt-5 red--text" justify="center">
        {{ error }}
      </v-row>
      <!-- Controls -->
      <v-row class="pt-5" align="center">
        <v-autocomplete v-model="selectedUser" label="Search by Display Name" :items="users" item-text="personName" item-value="idPerson" :disabled="loading" return-object/>
        <v-btn @click="selectedUserData={}" :disabled="loading" :loading="loading" color="primaryButton" class="ml-5 white--text">+ New User</v-btn>
      </v-row>
      <!-- User Form -->
      <UserForm v-if="selectedUserData" :inputData="selectedUserData" v-on:onSubmitComplete="onSubmitComplete"></UserForm>
      <!-- Confirm Disable User Dialog -->
      <v-dialog v-model="isShowingConfirmDisableUserDialog" width="500">
        <v-card class="pa-5">
          <v-card-title class="ma-0 pa-0">Disable User</v-card-title>
          <v-card-text class="ma-0 mt-5 pa-0">Are you sure you want to disable this user account?</v-card-text>
          <v-card-actions class="ma-0 mt-5 pa-0">
            <v-btn @click="disableSelectedUser(); isShowingConfirmDisableUserDialog=false" :disabled="loading" color="red">
              Disable
            </v-btn>
            <v-btn :disabled="loading" @click="isShowingConfirmDisableUserDialog=false" class="ml-2">
              Cancel
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- Confirm Logout User Dialog -->
      <v-dialog v-model="isShowingConfirmLogoutUserDialog" width="500">
        <v-card class="pa-5">
          <v-card-title class="ma-0 pa-0">Logout User</v-card-title>
          <v-card-text class="ma-0 mt-5 pa-0">Are you sure you want to logout this user account?</v-card-text>
          <v-card-actions class="ma-0 mt-5 pa-0">
            <v-btn @click="logoutSelectedUser(); isShowingConfirmLogoutUserDialog=false" :disabled="loading" color="red">
              Logout
            </v-btn>
            <v-btn :disabled="loading" @click="isShowingConfirmLogoutUserDialog=false" class="ml-2">
              Cancel
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- Confirm Logout All Users Dialog -->
      <v-dialog v-model="isShowingConfirmLogoutAllUsersDialog" width="500">
        <v-card class="pa-5">
          <v-card-title class="ma-0 pa-0">Logout All Users</v-card-title>
          <v-card-text class="ma-0 mt-5 pa-0">Are you sure you want to logout all users?</v-card-text>
          <v-card-actions class="ma-0 mt-5 pa-0">
            <v-btn @click="logoutAllUsers(); isShowingConfirmLogoutAllUsersDialog=false" :disabled="loading" color="red">
              Logout All
            </v-btn>
            <v-btn :disabled="loading" @click="isShowingConfirmLogoutAllUsersDialog=false" class="ml-2">
              Cancel
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
  import axios from 'axios'
  import UserForm from "@/components/UserForm"

  export default {
    name: 'Users',

    components: {
      UserForm
    },

    data () {
      return {
        error: '',
        isShowingConfirmDisableUserDialog: false,
        isShowingConfirmLogoutAllUsersDialog: false,
        isShowingConfirmLogoutUserDialog: false,
        isShowingOnlyAdmins: false,
        loading: false,
        selectedUser: null,
        selectedUserData: null,
        users: []
      }
    },

    created () {
      this.getUsers()
    },

    watch: {
      selectedUser () {
        window.scroll({ top: 0, behavior: 'smooth' })
        this.getSelectedUserData()
      }
    },

    methods: {
      disableSelectedUser () {
        this.loading = true
        var posts = []
        const req = { id: this.selectedUser.idUser }
        posts.push(axios.post('https://us-central1-dev-qabbb.cloudfunctions.net/api/disable', req))
        axios.all(posts)
        .then(axios.spread((data1, data2) => {
          console.log(data1, data2)
          this.loading = false
        }))
        .catch((error) => {
          this.error = error
          this.loading = false
        })
      },

      getSelectedUserData () {
        this.loading = true
        this.$store.dispatch('getUser', this.selectedUser.idPerson)
        .then((resp) => {
          this.selectedUserData = resp
          this.selectedUserData.isAdmin = this.selectedUser.roles != undefined && this.selectedUser.roles.indexOf('Admin') > -1
          this.loading = false
        })
        .catch((error) => {
          this.error = error
          this.loading = false
          if (this.error.code == 'permission-denied') {
            this.$store.dispatch('logout')
            .then(() => {
              this.$router.push('/login')
            })
          }
        })
      },

      getUsers () {
        const selectedUserID = this.selectedUser ? this.selectedUser.idPerson : this.$route.query.id
        this.loading = true
        this.$store.dispatch('getUsers')
        .then((resp) => {
          if (resp.errors) {
            this.error = resp.errors
          } else {
            this.users = resp.sort((a, b) => a.personName.toLowerCase() > b.personName.toLowerCase() ? 1 : -1)
            if (selectedUserID) this.selectUser(this.users.find(user => user.idPerson == selectedUserID))
            window.scroll({ top: 0, behavior: 'smooth' })
            this.$forceUpdate()
          }
          this.loading = false
        })
        .catch((error) => {
          this.error = error
          window.scroll({ top: 0, behavior: 'smooth' })
          this.loading = false
          if (this.error.code == 'permission-denied') {
            this.$store.dispatch('logout')
            .then(() => {
              this.$router.push('/login')
            })
          }
        })
      },

      logoutAllUsers () {
        this.loading = true
        var posts = []
        posts.push(axios.post('https://us-central1-dev-qabbb.cloudfunctions.net/api/logoutAll'))

        axios.all(posts)
        .then(axios.spread((data1, data2) => {
          console.log(data1, data2)
          this.loading = false
        }))
        .catch((error) => {
          this.error = error
          this.loading = false
        })
      },

      logoutSelectedUser () {
        this.loading = true
        var posts = []
        const req = { id: this.selectedUser.id }
        console.log('req before: ' + JSON.stringify(req))
        posts.push(axios.post('https://us-central1-dev-qabbb.cloudfunctions.net/api/logout', req))
        axios.all(posts)
        .then(axios.spread((data1, data2) => {
          console.log(data1, data2)
          this.loading = false
        }))
        .catch((error) => {
          this.error = error
          this.loading = false
        })
      },

      onSubmitComplete (userData) {
        this.selectedUserData = userData
        this.selectedUserData.isAdmin = this.selectedUser.roles != undefined && this.selectedUser.roles.indexOf('Admin') > -1
        this.getUsers() // make sure selected user is in the list of users
      },

      selectUser (user) {
        this.selectedUser = user
        this.getSelectedUserData()
        window.scroll({ top: 0, behavior: 'smooth' })
      }
    }
  }
</script>